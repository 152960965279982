import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ROUTES, ROUTES2 } from "./sidebar-routes.config";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../services/userService/user.service";
import { SseService } from "../../services/sse.service";
import { OrdersService } from "app/services/orders/orders.service";
import { VendorsService } from "../../services/vendors/vendors.service";
import { Subscription } from "rxjs";
import { SseEventTypes } from "../../@models/sse-events.model";
import { SseEndpointsSuffixes } from "../../@constants/sse-endpoints-suffixes.constant";
import { switchMap, take } from "rxjs/operators";
import {
  IOffering,
  OfferingApproveStatus,
} from "../../@models/offerings.model";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public menuItems: any[];
  public user: any;
  public subscriptions: Subscription[];

  constructor(
    private orderService: OrdersService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private vendorsService: VendorsService,
    private cdr: ChangeDetectorRef,
    private sseService: SseService
  ) {
    this.user = {};
  }

  ngOnInit() {
    $.getScript("./assets/js/app-sidebar.js");

    this.userService.getUser().subscribe(
      (user) => {
        this.user = user;
        if (user.role == "accounting") {
          this.menuItems = ROUTES2.filter((menuItem) => menuItem);
        } else {
          this.menuItems = ROUTES.filter((menuItem) => menuItem);
        }
        this.initSubscriptions();

        // this.orderService.getUndecidedCancelations().subscribe((orders) => {
        //   this.menuItems.find((item) => item.title == "Orders").badge =
        //     orders.length;
        //   this.cdr.detectChanges();
        // });

        this.getNewVendorsRegistrationsCount();

        this.getNumberOfOfferingsWaitingForApproval();

        // setInterval(() => {
        //     this.orderService.getUndecidedCancelations().subscribe((orders) => {
        //         this.menuItems.find(item => item.title == 'Orders').badge = orders.length;
        //         this.cdr.detectChanges();
        //     })
        // }, 10000)
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private getNewVendorsRegistrationsCount() {
    this.vendorsService.getNewRegistrationsCount().subscribe(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  initSubscriptions() {
    this.subscriptions = [
      this.vendorsService.newRegistrationsCount$.subscribe((registrations) => {
        const vendorsMenuitem = this.menuItems.find(
          (item) => item.title === "Vendors"
        );
        if (vendorsMenuitem) {
          vendorsMenuitem.submenu.find(
            (item) => item.title === "Registrations"
          ).badge = registrations;
          this.cdr.detectChanges();
        }
      }),
      this.vendorsService.newOfferingsCount$.subscribe((offerings) => {
        const vendorsMenuitem = this.menuItems.find(
          (item) => item.title === "Vendors"
        );
        if (vendorsMenuitem) {
          vendorsMenuitem.submenu.find(
            (item) => item.title === "Offering Approval"
          ).badge = offerings;
          this.cdr.detectChanges();
        }
      }),
      this.sseService
        .createEventSource(SseEndpointsSuffixes.vendorsRegistrations, [
          SseEventTypes.vendorRegistrationCreated,
        ])
        .pipe(
          switchMap(() =>
            this.vendorsService.newRegistrationsCount$.pipe(take(1))
          )
        )
        .subscribe((registrationsCount: number) => {
          this.vendorsService.newRegistrationsCount$.next(
            registrationsCount + 1
          );
        }),
      this.sseService
        .createEventSource(SseEndpointsSuffixes.offerings, [
          SseEventTypes.offeringCreated,
          SseEventTypes.offeringUpdated,
        ])
        .subscribe((event) => {
          if ((event.data as IOffering).isRead === false) {
            this.getNumberOfOfferingsWaitingForApproval();
            this.vendorsService.offeringSseEvent$.next(event.data as IOffering);
          }
        }),
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private getNumberOfOfferingsWaitingForApproval() {
    this.vendorsService.getNumberOfOfferingsWaitingForApproval().subscribe(
      (_) => {},
      (err) => {
        console.log(err);
      }
    );
  }

  //NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1) {
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
    }
  }
}
