import { AuthService } from './shared/auth/auth.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(auth) {
        var _this = this;
        this.auth = auth;
        setInterval(function () {
            _this.auth.refresh().subscribe();
        }, 900000);
        // this.scheduleRefresh()
    }
    return AppComponent;
}());
export { AppComponent };
// TODO order view issue
// TODO order 3 days feature
// TODO cancelation API slow
// TODO offering model multi-select
