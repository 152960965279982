import { IDateRange } from "../@models/date-range.interface";
import { isEqual } from "date-fns";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";

export const isDateRangeEqual = (rangeA: IDateRange, rangeB: IDateRange) =>
  isEqual(rangeA.from, rangeB.from) && isEqual(rangeA.to, rangeB.to);

export const toLocalIsoDate = (date: Date): string => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000;
  return (new Date(date.getTime() - tzoffset)).toISOString().slice(0, -1);
}

export const ngbDateStructToLocalIsoDate = (date: NgbDateStruct): string =>
  toLocalIsoDate( new Date(date.year, date.month - 1, date.day));

