import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals';
import { IOrdersForReportRequestParams, IOrdersRequestParams} from "../../@models/orders-request-params.interface";
import { requestQuery } from "../../@utils/request-query.util";
import { PagingResponse} from "../../@models/pagination-response.model";
import { BehaviorSubject, Observable } from "rxjs";
import { isNil } from "lodash-es";
import { OrdersReportResponseDto } from "../../@models/orders-report-response.dto";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  isOrdersStateNeedToBeRestored$ = new BehaviorSubject<boolean>(false);
  ordersStoredVerticalOffset$ = new BehaviorSubject<number | null>(null);
  ordersStoredTab$ = new BehaviorSubject<number | null>(null);
  ordersRequestParams$ = new BehaviorSubject<IOrdersRequestParams | null>(null);

  constructor(private http: HttpClient, private globals: Globals) {

  }

  getAll() {
    console.log('------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,');
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders`);
  }
  getAllWithout() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/admin`);
  }
  getDataWithPagination(data, page) {
    let queryParam = '?';
    queryParam = (queryParam + 'orderId=') + (data.orderId ? data.orderId : '');
    queryParam = (queryParam + '&vendorId=') + (data.vendorId ? data.vendorId : '');
    queryParam = (queryParam + '&eventDate=') + (data.eventDate ? data.eventDate : '');
    queryParam = (queryParam + '&orderStatus=') + (data.orderStatus ? data.orderStatus : '');
    queryParam = queryParam + '&page=' + page;
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/admin-with-page${queryParam}`);
  }

  getUndecidedCancelations() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/cancelations`);
  }

  reviewOrder(orderDbId, isApproved) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/orders/${orderDbId}/cancelations/review`, { result: isApproved });
  }


  get(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/${id}`);
  }

  cancel(id: string) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/orders/${id}/cancel`, {});
  }
  getAllcash_register() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/cash_register`);
  }


  getcash_register(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/cash_register/${id}`);
  }


  cancelcash_register(id: string) {
    return this.http.delete<any>(`${this.globals.config.apiUrl}/cash_register/${id}`);
  }
  sendmail(email) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/admin/sendvendorinvoice`, email);
  }
  addcash_register(cash_register: any) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/cash_register`, cash_register);
  }
  //   getOfferings(id: string) {
  //     return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/${id}/offerings`);
  //   }

  //   add(vendor: any) {
  //     return this.http.post<any>(`${this.globals.config.apiUrl}/vendors`, vendor);
  //   }

  //   update(vendor: any) {
  //     return this.http.put<any>(`${this.globals.config.apiUrl}/vendors/${vendor._id}`, vendor);
  //   }

  //   delete(id: string) {
  //     return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
  //   }
  getOrderByDate(vendor, from, to) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/orders/order-report-admin/${vendor}/${from}/${to}`);
  }

  getOrders(params: IOrdersRequestParams): Observable<PagingResponse<any>> { // TODO: add interface for order
    this.ordersRequestParams$.next(params);
    let query = requestQuery(params);
    return this.http.get<PagingResponse<any>>(`${this.globals.config.apiV2Url}/admin/orders?${query}`);
  }

  getOrdersForReport(params: IOrdersForReportRequestParams): Observable<OrdersReportResponseDto> {
    let query = requestQuery(params);
    return this.http.get<OrdersReportResponseDto>(`${this.globals.config.apiV2Url}/admin/orders/report/index?${query}`);
  }

  downloadOrdersReport(params: IOrdersForReportRequestParams): Observable<Blob> {
    let query = requestQuery(params);
    const headers = new HttpHeaders({
      'content-type': 'application/octet-stream',
    });
    return this.http.get(`${this.globals.config.apiV2Url}/admin/orders/report/file?${query}`, { headers: headers, responseType: 'blob' });
  }

}
