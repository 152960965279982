import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";

export const userKey = 'currentUser';



export const projectBirthDate: NgbDateStruct = {
  year: 2018,
  month: 10,
  day: 14,
};

export const projectBirthDateAsDate: Date = new Date(projectBirthDate.year, projectBirthDate.month - 1, projectBirthDate.day);
