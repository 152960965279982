import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../globals';
import { requestQuery } from "../../@utils/request-query.util";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../globals";
var OrdersService = /** @class */ (function () {
    function OrdersService(http, globals) {
        this.http = http;
        this.globals = globals;
        this.isOrdersStateNeedToBeRestored$ = new BehaviorSubject(false);
        this.ordersStoredVerticalOffset$ = new BehaviorSubject(null);
        this.ordersStoredTab$ = new BehaviorSubject(null);
        this.ordersRequestParams$ = new BehaviorSubject(null);
    }
    OrdersService.prototype.getAll = function () {
        console.log('------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,------------------getAll,,,,,,,,,,,getAll,,,,,,,,,,,,getAll,,,,,,,,,,,,');
        return this.http.get(this.globals.config.apiUrl + "/orders");
    };
    OrdersService.prototype.getAllWithout = function () {
        return this.http.get(this.globals.config.apiUrl + "/orders/admin");
    };
    OrdersService.prototype.getDataWithPagination = function (data, page) {
        var queryParam = '?';
        queryParam = (queryParam + 'orderId=') + (data.orderId ? data.orderId : '');
        queryParam = (queryParam + '&vendorId=') + (data.vendorId ? data.vendorId : '');
        queryParam = (queryParam + '&eventDate=') + (data.eventDate ? data.eventDate : '');
        queryParam = (queryParam + '&orderStatus=') + (data.orderStatus ? data.orderStatus : '');
        queryParam = queryParam + '&page=' + page;
        return this.http.get(this.globals.config.apiUrl + "/orders/admin-with-page" + queryParam);
    };
    OrdersService.prototype.getUndecidedCancelations = function () {
        return this.http.get(this.globals.config.apiUrl + "/orders/cancelations");
    };
    OrdersService.prototype.reviewOrder = function (orderDbId, isApproved) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + orderDbId + "/cancelations/review", { result: isApproved });
    };
    OrdersService.prototype.get = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/orders/" + id);
    };
    OrdersService.prototype.cancel = function (id) {
        return this.http.put(this.globals.config.apiUrl + "/orders/" + id + "/cancel", {});
    };
    OrdersService.prototype.getAllcash_register = function () {
        return this.http.get(this.globals.config.apiUrl + "/cash_register");
    };
    OrdersService.prototype.getcash_register = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/cash_register/" + id);
    };
    OrdersService.prototype.cancelcash_register = function (id) {
        return this.http.delete(this.globals.config.apiUrl + "/cash_register/" + id);
    };
    OrdersService.prototype.sendmail = function (email) {
        return this.http.post(this.globals.config.apiUrl + "/admin/sendvendorinvoice", email);
    };
    OrdersService.prototype.addcash_register = function (cash_register) {
        return this.http.post(this.globals.config.apiUrl + "/cash_register", cash_register);
    };
    //   getOfferings(id: string) {
    //     return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/${id}/offerings`);
    //   }
    //   add(vendor: any) {
    //     return this.http.post<any>(`${this.globals.config.apiUrl}/vendors`, vendor);
    //   }
    //   update(vendor: any) {
    //     return this.http.put<any>(`${this.globals.config.apiUrl}/vendors/${vendor._id}`, vendor);
    //   }
    //   delete(id: string) {
    //     return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
    //   }
    OrdersService.prototype.getOrderByDate = function (vendor, from, to) {
        return this.http.get(this.globals.config.apiUrl + "/orders/order-report-admin/" + vendor + "/" + from + "/" + to);
    };
    OrdersService.prototype.getOrders = function (params) {
        this.ordersRequestParams$.next(params);
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/admin/orders?" + query);
    };
    OrdersService.prototype.getOrdersForReport = function (params) {
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/admin/orders/report/index?" + query);
    };
    OrdersService.prototype.downloadOrdersReport = function (params) {
        var query = requestQuery(params);
        var headers = new HttpHeaders({
            'content-type': 'application/octet-stream',
        });
        return this.http.get(this.globals.config.apiV2Url + "/admin/orders/report/file?" + query, { headers: headers, responseType: 'blob' });
    };
    OrdersService.ngInjectableDef = i0.defineInjectable({ factory: function OrdersService_Factory() { return new OrdersService(i0.inject(i1.HttpClient), i0.inject(i2.Globals)); }, token: OrdersService, providedIn: "root" });
    return OrdersService;
}());
export { OrdersService };
