import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../../globals";
import { IUsersRequestParams } from "../../@models/users-request-params.interface";
import { requestQuery } from "../../@utils/request-query.util";
import { Observable } from "rxjs";
import { PagingResponse } from "../../@models/pagination-response.model";
import { User } from "../../@models/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private globals: Globals) {}

  getAll() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/users`);
  }

  getPaginatedList(
    params: IUsersRequestParams
  ): Observable<PagingResponse<User>> {
    const query = requestQuery(params);
    return this.http.get<PagingResponse<User>>(
      `${this.globals.config.apiV2Url}/admin/users?${query}`
    );
  }

  getReport(params: IUsersRequestParams): Observable<Blob> {
    const query = requestQuery(params);
    const headers = new HttpHeaders({
      "content-type": "application/octet-stream",
    });
    return this.http.get(
      `${this.globals.config.apiV2Url}/admin/users/report/file?${query}`,
      { headers: headers, responseType: "blob" }
    );
  }

  getUser() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/users/me`);
  }

  createVendorUser(user) {
    return this.http.post<any>(
      `${this.globals.config.apiV2Url}/vendors/${user._vendor}/users/create`,
      user
    );
  }

  updateVendorUser(user) {
    return this.http.put<any>(
      `${this.globals.config.apiV2Url}/vendors/${user._vendor}/users/${user._id}/edit`,
      user
    );
  }

  getVendorUsers(vendorId) {
    return this.http.get<any>(
      `${this.globals.config.apiV2Url}/vendors/${vendorId}/users`
    );
  }

  blockUser(id) {
    return this.http.post<any>(
      `${this.globals.config.apiUrl}/users/` + id + "/block",
      {}
    );
  }

  blockUserV2(vendorId, userId, isBlocked) {
    return this.http.put<any>(
      `${this.globals.config.apiV2Url}/vendors/${vendorId}/users/${userId}/block`,
      { isBlocked }
    );
  }

  unblockUser(id) {
    return this.http.post<any>(
      `${this.globals.config.apiUrl}/users/` + id + "/unblock",
      {}
    );
  }
}
