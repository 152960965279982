import { Routes, RouterModule } from "@angular/router";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "",
    redirectTo: "dashboard/dashboard1",
    pathMatch: "full",
  },
  {
    path: "dashboard",
    loadChildren: "./dashboard/dashboard.module#DashboardModule",
  },
  {
    path: "promocodes",
    loadChildren: "./promocodes/promocodes.module#PromoCodesModule",
  },
  {
    path: "offeringcategories",
    loadChildren:
      "./offering-categories/offering-categories.module#OfferingCategoriesModule",
  },
  {
    path: "vendors",
    loadChildren: "./vendors/vendors.module#VendorsModule",
  },
  {
    path: "orders",
    loadChildren: "./orders/orders.module#OrdersModule",
  },
  {
    path: "searchcategorysections",
    loadChildren:
      "./search-category-sections/search-category-sections.module#SearchCategorySectionsModule",
  },
  {
    path: "vendorofferings",
    loadChildren:
      "./vendor-offerings/vendor-offerings.module#VendorOfferingsModule",
  },
  {
    path: "customers",
    loadChildren: "./customer/customer.module#CustomerModule",
  },
  {
    path: "offeringapproval",
    loadChildren:
      "./offerings-approval/offerings-approval.module#OfferingApprovalModule",
  },
  {
    path: "collections",
    loadChildren:
      "./offering-collections/offering-collections.module#OfferingCollectionsModule",
  },
  {
    path: "popular",
    loadChildren:
      "./popular/popular-collections.module#PopularCollectionsModule",
  },
  {
    path: "calendar",
    loadChildren: "./calendar/calendar.module#CalendarsModule",
  },
  {
    path: "filter-types",
    loadChildren: "./filter-types/filter-types.module#FilterTypesModule",
  },
  {
    path: "charts",
    loadChildren: "./charts/charts.module#ChartsNg2Module",
  },
  {
    path: "forms",
    loadChildren: "./forms/forms.module#FormModule",
  },
  {
    path: "maps",
    loadChildren: "./maps/maps.module#MapsModule",
  },
  {
    path: "tables",
    loadChildren: "./tables/tables.module#TablesModule",
  },
  {
    path: "datatables",
    loadChildren: "./data-tables/data-tables.module#DataTablesModule",
  },
  {
    path: "uikit",
    loadChildren: "./ui-kit/ui-kit.module#UIKitModule",
  },
  {
    path: "components",
    loadChildren: "./components/ui-components.module#UIComponentsModule",
  },
  {
    path: "pages",
    loadChildren: "./pages/full-pages/full-pages.module#FullPagesModule",
  },
  {
    path: "cards",
    loadChildren: "./cards/cards.module#CardsModule",
  },
  {
    path: "colorpalettes",
    loadChildren: "./color-palette/color-palette.module#ColorPaletteModule",
  },
  {
    path: "chat",
    loadChildren: "./chat/chat.module#ChatModule",
  },
  {
    path: "chat-ngrx",
    loadChildren: "./chat-ngrx/chat-ngrx.module#ChatNGRXModule",
  },
  {
    path: "inbox",
    loadChildren: "./inbox/inbox.module#InboxModule",
  },
  {
    path: "taskboard",
    loadChildren: "./taskboard/taskboard.module#TaskboardModule",
  },
  {
    path: "taskboard-ngrx",
    loadChildren: "./taskboard-ngrx/taskboard-ngrx.module#TaskboardNGRXModule",
  },
  {
    path: "player",
    loadChildren: "./player/player.module#PlayerModule",
  },
  {
    path: "locations",
    loadChildren: "./locations/locations.module#LocationsModule",
  }
];
