import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Globals } from "../../globals";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { IVendorsRequestParams } from "../../@models/vendors-request-params.interface";
import { requestQuery } from "../../@utils/request-query.util";
import { PagingResponse } from "../../@models/pagination-response.model";
import { IVendorsRegistrationsRequestParams } from "../../@models/vendors-registrations-request-params.interface";
import { IOfferingsRequestParams } from "../../@models/offerings-request-params.interface";
import { switchMap, take, tap, map } from "rxjs/operators";
import { IOffering } from "../../@models/offerings.model";
import { IBriefVendor, IVendor } from "../../@models/vendor.model";
import {
  IVendorPolicy,
  IVendorPolicyResponse,
} from "app/vendors/vendor-policies/vendor-policy.interface";

@Injectable({
  providedIn: "root",
})
export class VendorsService {
  isVendorsStateNeedToBeRestored$ = new BehaviorSubject<boolean>(false);
  vendorsStoredVerticalOffset$ = new BehaviorSubject<number | null>(null);
  vendorsStoredTab$ = new BehaviorSubject<number | null>(null);
  vendorsRequestParams$ = new BehaviorSubject<IVendorsRequestParams | null>(null);
  newRegistrationsCount$ = new ReplaySubject<number>(1);
  newOfferingsCount$ = new BehaviorSubject<number>(0);
  offeringSseEvent$ = new Subject<IOffering>();

  constructor(private http: HttpClient, private globals: Globals) {}

  getAll() {
    return this.http.get<any>(`${this.globals.config.apiUrl}/vendors/admin`);
  }

  getAllVendorList(): Observable<IBriefVendor[]> {
    return this.http.get<IBriefVendor[]>(
      `${this.globals.config.apiUrl}/vendors/list`
    );
  }

  get(id: string): Observable<IVendor> {
    return this.http.get<IVendor>(`${this.globals.config.apiUrl}/vendors/${id}`);
  }

  getDraftById(id: string): Observable<IVendor> {
    return this.http.get<IVendor>(`${this.globals.config.apiV2Url}/admin/draft-vendors/${id}`);
  }

  getOfferings(id: string) {
    return this.http.get<any>(
      `${this.globals.config.apiUrl}/vendors/${id}/offeringsadmin`
    );
  }

  add(vendor: any) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/vendors`, vendor);
  }

  createDraft(vendor: any) {
    return this.http.post<any>(`${this.globals.config.apiV2Url}/admin/draft-vendors`, vendor);
  }

  update(vendor: any) {
    return this.http.put<any>(
      `${this.globals.config.apiUrl}/vendors/${vendor._id}`,
      vendor
    );
  }

  updateDraft(vendor: any) {
    return this.http.patch<any>(`${this.globals.config.apiV2Url}/admin/draft-vendors/${vendor._id}`, vendor);
  }

  saveDraftAsVendor(vendor: any) {
    return this.http.patch<any>(`${this.globals.config.apiV2Url}/admin/draft-vendors/${vendor._id}/vendor`, vendor);
  }

  delete(id: string) {
    return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}`);
  }

  deleteDraft(id: string) {
    return this.http.delete<any>(`${this.globals.config.apiV2Url}/admin/draft-vendors/${id}`);
  }

  deleteImage(id: string, key: string, serviceType: string) {
    return this.http.delete<any>(`${this.globals.config.apiUrl}/vendors/${id}/images/${serviceType}/${encodeURIComponent(key)}`);
  }

  deleteLogoFromDraft(id: string) {
    return this.http.delete<any>(`${this.globals.config.apiV2Url}/admin/draft-vendors/${id}/logo`);
  }

  deleteImageFromDraft(id: string, key: string, serviceType: string) {
    return this.http.delete<any>(`${this.globals.config.apiV2Url}/admin/draft-vendors/${id}/images/${serviceType}/${encodeURIComponent(key)}`);
  }

  deleteOffering(id: string) {
    return this.http.delete<any>(
      `${this.globals.config.apiUrl}/offerings/${id}`
    );
  }

  getOffering(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/offerings/${id}`);
  }

  getNumberOfOfferingsWaitingForApproval() {
    return this.http.get<any>(`${this.globals.config.apiV2Url}/admin/offerings/on-approval/count`).pipe(
      tap(res => this.newOfferingsCount$.next(res.result))
    );
  }

  getRegistrations(params: IVendorsRegistrationsRequestParams): Observable<PagingResponse<any>> {
    const query = requestQuery(params);
    return this.http.get<PagingResponse<any>>(`${this.globals.config.apiV2Url}/admin/vendors/registrations?${query}`);
  }

  markRegistrationsAsRead(registrations: string[]) {
    return this.http.post<any>(`${this.globals.config.apiV2Url}/admin/vendors/registrations/unread`, { registrationIds: registrations }).pipe(
      switchMap(() => this.newRegistrationsCount$.pipe(take(1))),
      tap(oldRegistrationsCount => {
        const newRegistrationsCount = oldRegistrationsCount - registrations.length;
        this.newRegistrationsCount$.next(newRegistrationsCount);
      })
    );
  }

  updateOffering(id: string, update: any) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/offerings/${id}`, update).pipe(
      tap(() => this.reduceOfferingsWaitingForApproval())
    );
  }

  updateVendorOrdering(updateDetails) {
    //uvo: update vendor ordering
    return this.http.post<any>(`${this.globals.config.apiUrl}/vendors/uvo`, updateDetails)
  }

  updateOfferingOrderForVendor(vendorId, updateDetails) {
    //uvo: update vendor ordering
    return this.http.post<any>(`${this.globals.config.apiUrl}/vendors/${vendorId}/reorderofferings`, updateDetails)
  }

  getOffersWaitingForApproval(requestParams: IOfferingsRequestParams): Observable<PagingResponse<any>> { // TODO: Add interface for offering
    let query = requestQuery(requestParams);
    return this.http.get<PagingResponse<any>>(`${this.globals.config.apiV2Url}/admin/offerings?${query}`);
  }

  private reduceOfferingsWaitingForApproval() {
    const oldOfferingsCount = this.newOfferingsCount$.getValue();
    if (oldOfferingsCount) {
      this.newOfferingsCount$.next(oldOfferingsCount - 1);
    }
  }

  approveOffering(id) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/offerings/approve/${id}`, {}).pipe(
      tap(() => this.reduceOfferingsWaitingForApproval())
    );
  }

  rejectOffering(id) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/offerings/reject/${id}`, {}).pipe(
      tap(() => this.reduceOfferingsWaitingForApproval())
    );
  }

  getVendorReviews(id: string) {
    return this.http.get<any>(`${this.globals.config.apiUrl}/reviews/vendor/${id}`);
  }

  getNewRegistrationsCount() {
    return this.http.get<{ result: number }>(`${this.globals.config.apiV2Url}/admin/vendors/registrations/unread`).pipe(
      tap(res => this.newRegistrationsCount$.next(res.result))
    );
  }

  approveDeleteOffering(id) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/offerings/approve-delete/${id}`, {}).pipe(
      tap(() => this.reduceOfferingsWaitingForApproval())
    );
  }

  rejectDeleteOffering(id) {
    return this.http.put<any>(`${this.globals.config.apiUrl}/offerings/reject-delete/${id}`, {}).pipe(
      tap(() => this.reduceOfferingsWaitingForApproval())
    );
  }

  getVendors(params: IVendorsRequestParams): Observable<PagingResponse<IVendor>> {
    this.vendorsRequestParams$.next(params);
    const query = requestQuery(params);
    return this.http.get<PagingResponse<IVendor>>(
      `${this.globals.config.apiV2Url}/admin/vendors?${query}`
    );
  }

  getPolicy(): Observable<IVendorPolicy> {
    return this.http
      .get<IVendorPolicyResponse>(`${this.globals.config.apiV2Url}/policy`)
      .pipe(map((res) => res.result.policy));
  }

  updatePolicy(policy: IVendorPolicy): Observable<IVendorPolicy> {
    return this.http
      .patch<IVendorPolicyResponse>(
        `${this.globals.config.apiV2Url}/policy`,
        policy
      )
      .pipe(map((res) => res.result.policy));
  }

  getDraftVendors(params: IVendorsRequestParams): Observable<PagingResponse<IVendor>> {
    this.vendorsRequestParams$.next(params);
    const query = requestQuery(params);
    return this.http.get<PagingResponse<IVendor>>(
      `${this.globals.config.apiV2Url}/admin/draft-vendors?${query}`
    );
  }
}
