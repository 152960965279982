var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Observable } from "rxjs";
import { Globals } from "../globals";
import { userKey } from "../@constants/main.constant";
import { EventSourcePolyfill } from 'event-source-polyfill/src/eventsource.min.js';
import * as i0 from "@angular/core";
import * as i1 from "../globals";
var SseService = /** @class */ (function () {
    function SseService(globals) {
        this.globals = globals;
    }
    SseService.prototype.createEventSource = function (urlSuffix, eventTypes) {
        var sseUrl = this.globals.config.apiV2Url + "/" + urlSuffix;
        var token = JSON.parse(localStorage.getItem(userKey));
        if (token) {
            var eventSource_1 = new EventSourcePolyfill(sseUrl, { headers: { Authorization: "JWT " + token.token } });
            return new Observable(function (observer) {
                var _loop_1 = function (eventType) {
                    eventSource_1.addEventListener(eventType, function (event) {
                        var messageData = JSON.parse(event.data);
                        observer.next(__assign({ type: eventType }, messageData));
                    });
                };
                for (var _i = 0, eventTypes_1 = eventTypes; _i < eventTypes_1.length; _i++) {
                    var eventType = eventTypes_1[_i];
                    _loop_1(eventType);
                }
                eventSource_1.onerror = function (e) {
                    console.log("Error with sse connection on " + sseUrl, e);
                };
                eventSource_1.onopen = function (event) {
                    console.log("Opened sse connection on " + sseUrl);
                };
            });
        }
    };
    SseService.ngInjectableDef = i0.defineInjectable({ factory: function SseService_Factory() { return new SseService(i0.inject(i1.Globals)); }, token: SseService, providedIn: "root" });
    return SseService;
}());
export { SseService };
