import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { Globals } from 'app/globals';
import { Observable } from 'rxjs';
import { userKey } from "../../@constants/main.constant";

@Injectable()
export class AuthService {
  token: string;
  config = {apiUrl: "http://localhost:3001/api/v1"}
  constructor(private http: HttpClient,
    private globals: Globals,
    private router: Router) {


  }

  ngOnInit(): void {
    if(localStorage.getItem(userKey)){
      setInterval(() => {
        this.refresh()
      }, 1000)
    }
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    return this.http.post<any>(`${this.globals.config.apiUrl}/users/login/admin`, { email, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(userKey, JSON.stringify(user));
                }

                return user;
            }));
  }

  refresh(): Observable<any>{
    return Observable.create((observer: any) => {
      this.http.get<any>(`${this.globals.config.apiUrl}/users/refresh`).subscribe(user => {
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          observer.next(user)

        }else{
          observer.next(user)
        }
      }, err => {
        console.log(err)
        observer.error(err)
      })
    })
  }

  logout() {
    localStorage.removeItem(userKey);
    this.router.navigate(['login'])
  }

  getToken() {
    return this.token;
  }

  getTokenFromStorage(){
    let user = JSON.parse(localStorage.getItem('currentUser'));
    return user.token;
  }
  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  isAuthenticated() {
    var token = JSON.parse(localStorage.getItem('currentUser'));
    if(token && token.token){
      let parsedToken = this.parseJwt(token.token)
      var isExpiredToken = false;
      var seconds = 1000;
      var d = new Date();
      var t= d.getTime();


      if (parsedToken.exp < Math.round(t / seconds)) {
        // code...
        isExpiredToken = true;
        localStorage.removeItem('currentUser');
      }
      return !isExpiredToken;
    }else{
      return false;
    }
  }


  tokenExpired(parsedToken){
    var isExpiredToken = false;
    var seconds = 1000;
    var d = new Date();
    var t= d.getTime();

    if (parsedToken.exp < Math.round(t / seconds)) {
      return true
    }

    return false
  }


  getParsedToken(){
    let token = JSON.parse(localStorage.getItem('currentUser'));
    if(token && token.token){
      return this.parseJwt(token.token);
    }
    return ''
  }


  async isAuthenticatedAsync(tryagain = true) {
    let parsedToken = this.getParsedToken();
    if(parsedToken){
      if ( this.tokenExpired(parsedToken)) {
        // console.log('expired')


        await this.refresh()
        let parsedToken = this.getParsedToken();
        if(this.tokenExpired(parsedToken)){
          return new Promise((resolve, reject) => {
            localStorage.removeItem('currentUser');
            // console.log('refresh failed')
            resolve(false)
          })
        } else {
          return new Promise((resolve, reject) => {
            // console.log('refresh succeeded')
            resolve(true)
          })
        }


        // code...

      } else {
        return new Promise((resolve, reject) => {
          // console.log('token valid')
          resolve(true)
        })
      }


      // return !isExpiredToken;
    }else{
      // console.log('rej, no token')
      return new Promise((resolve, reject) => {
        resolve(false)
      })
    }



  }
}
