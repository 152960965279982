import { IOrder } from "./order.interface";
import {IOffering} from "./offerings.model";

export enum SseEventTypes {
  orderCreated = 'order.created',
  orderUpdated = 'order.updated',
  vendorRegistrationCreated = 'vendor.registration.created',
  vendorRegistrationUpdated = 'vendor.registration.created',
  offeringCreated = 'offering.created',
  offeringUpdated = 'offering.updated',
}

export interface ISseMessage {
type: SseEventTypes;
data: IOrder | IOffering;
id: string;
}
