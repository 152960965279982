import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, of, throwError} from 'rxjs';
import { catchError, tap, first, flatMap, switchMap } from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';
import { userKey } from "../../@constants/main.constant";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

    constructor(public auth: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let token = JSON.parse(localStorage.getItem(userKey));
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `JWT ${token.token}`
                }
            });
        }

        return next.handle(request).pipe(catchError((error: HttpErrorResponse, cought) => {
            /**
             * 401 from server
             */
            if (error.status === 401){
                console.log('unauthorized')

                return this.refreshToken().pipe(switchMap(() => {
                    let token = JSON.parse(localStorage.getItem(userKey));
                    if (token) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `JWT ${token.token}`
                            }
                        });
                    }

                    return next.handle(request).pipe(catchError((error: HttpErrorResponse) => {
                        if (error.status === 401){
                            this.auth.logout()
                        }
                        return throwError(error)
                    }));
                }))
            }else{
                console.log(error)
                return throwError(error)
            }
        }));
    }

    refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;

            return this.auth.refresh()
               .pipe(tap(() => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next();
                }));
        }
    }
}

