var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Globals } from 'app/globals';
import { Observable } from 'rxjs';
import { userKey } from "../../@constants/main.constant";
var AuthService = /** @class */ (function () {
    function AuthService(http, globals, router) {
        this.http = http;
        this.globals = globals;
        this.router = router;
        this.config = { apiUrl: "http://localhost:3001/api/v1" };
    }
    AuthService.prototype.ngOnInit = function () {
        var _this = this;
        if (localStorage.getItem(userKey)) {
            setInterval(function () {
                _this.refresh();
            }, 1000);
        }
    };
    AuthService.prototype.signupUser = function (email, password) {
        //your code for signing up the new user
    };
    AuthService.prototype.signinUser = function (email, password) {
        return this.http.post(this.globals.config.apiUrl + "/users/login/admin", { email: email, password: password })
            .pipe(map(function (user) {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem(userKey, JSON.stringify(user));
            }
            return user;
        }));
    };
    AuthService.prototype.refresh = function () {
        var _this = this;
        return Observable.create(function (observer) {
            _this.http.get(_this.globals.config.apiUrl + "/users/refresh").subscribe(function (user) {
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    observer.next(user);
                }
                else {
                    observer.next(user);
                }
            }, function (err) {
                console.log(err);
                observer.error(err);
            });
        });
    };
    AuthService.prototype.logout = function () {
        localStorage.removeItem(userKey);
        this.router.navigate(['login']);
    };
    AuthService.prototype.getToken = function () {
        return this.token;
    };
    AuthService.prototype.getTokenFromStorage = function () {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        return user.token;
    };
    AuthService.prototype.parseJwt = function (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    };
    AuthService.prototype.isAuthenticated = function () {
        var token = JSON.parse(localStorage.getItem('currentUser'));
        if (token && token.token) {
            var parsedToken = this.parseJwt(token.token);
            var isExpiredToken = false;
            var seconds = 1000;
            var d = new Date();
            var t = d.getTime();
            if (parsedToken.exp < Math.round(t / seconds)) {
                // code...
                isExpiredToken = true;
                localStorage.removeItem('currentUser');
            }
            return !isExpiredToken;
        }
        else {
            return false;
        }
    };
    AuthService.prototype.tokenExpired = function (parsedToken) {
        var isExpiredToken = false;
        var seconds = 1000;
        var d = new Date();
        var t = d.getTime();
        if (parsedToken.exp < Math.round(t / seconds)) {
            return true;
        }
        return false;
    };
    AuthService.prototype.getParsedToken = function () {
        var token = JSON.parse(localStorage.getItem('currentUser'));
        if (token && token.token) {
            return this.parseJwt(token.token);
        }
        return '';
    };
    AuthService.prototype.isAuthenticatedAsync = function (tryagain) {
        if (tryagain === void 0) { tryagain = true; }
        return __awaiter(this, void 0, void 0, function () {
            var parsedToken, parsedToken_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        parsedToken = this.getParsedToken();
                        if (!parsedToken) return [3 /*break*/, 4];
                        if (!this.tokenExpired(parsedToken)) return [3 /*break*/, 2];
                        // console.log('expired')
                        return [4 /*yield*/, this.refresh()];
                    case 1:
                        // console.log('expired')
                        _a.sent();
                        parsedToken_1 = this.getParsedToken();
                        if (this.tokenExpired(parsedToken_1)) {
                            return [2 /*return*/, new Promise(function (resolve, reject) {
                                    localStorage.removeItem('currentUser');
                                    // console.log('refresh failed')
                                    resolve(false);
                                })];
                        }
                        else {
                            return [2 /*return*/, new Promise(function (resolve, reject) {
                                    // console.log('refresh succeeded')
                                    resolve(true);
                                })];
                        }
                        return [3 /*break*/, 3];
                    case 2: return [2 /*return*/, new Promise(function (resolve, reject) {
                            // console.log('token valid')
                            resolve(true);
                        })];
                    case 3: return [3 /*break*/, 5];
                    case 4: 
                    // console.log('rej, no token')
                    return [2 /*return*/, new Promise(function (resolve, reject) {
                            resolve(false);
                        })];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    return AuthService;
}());
export { AuthService };
