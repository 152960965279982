import { HttpClient } from "@angular/common/http";
import { Globals } from "../../globals";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { requestQuery } from "../../@utils/request-query.util";
import { switchMap, take, tap, map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../globals";
var VendorsService = /** @class */ (function () {
    function VendorsService(http, globals) {
        this.http = http;
        this.globals = globals;
        this.isVendorsStateNeedToBeRestored$ = new BehaviorSubject(false);
        this.vendorsStoredVerticalOffset$ = new BehaviorSubject(null);
        this.vendorsStoredTab$ = new BehaviorSubject(null);
        this.vendorsRequestParams$ = new BehaviorSubject(null);
        this.newRegistrationsCount$ = new ReplaySubject(1);
        this.newOfferingsCount$ = new BehaviorSubject(0);
        this.offeringSseEvent$ = new Subject();
    }
    VendorsService.prototype.getAll = function () {
        return this.http.get(this.globals.config.apiUrl + "/vendors/admin");
    };
    VendorsService.prototype.getAllVendorList = function () {
        return this.http.get(this.globals.config.apiUrl + "/vendors/list");
    };
    VendorsService.prototype.get = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/vendors/" + id);
    };
    VendorsService.prototype.getDraftById = function (id) {
        return this.http.get(this.globals.config.apiV2Url + "/admin/draft-vendors/" + id);
    };
    VendorsService.prototype.getOfferings = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/vendors/" + id + "/offeringsadmin");
    };
    VendorsService.prototype.add = function (vendor) {
        return this.http.post(this.globals.config.apiUrl + "/vendors", vendor);
    };
    VendorsService.prototype.createDraft = function (vendor) {
        return this.http.post(this.globals.config.apiV2Url + "/admin/draft-vendors", vendor);
    };
    VendorsService.prototype.update = function (vendor) {
        return this.http.put(this.globals.config.apiUrl + "/vendors/" + vendor._id, vendor);
    };
    VendorsService.prototype.updateDraft = function (vendor) {
        return this.http.patch(this.globals.config.apiV2Url + "/admin/draft-vendors/" + vendor._id, vendor);
    };
    VendorsService.prototype.saveDraftAsVendor = function (vendor) {
        return this.http.patch(this.globals.config.apiV2Url + "/admin/draft-vendors/" + vendor._id + "/vendor", vendor);
    };
    VendorsService.prototype.delete = function (id) {
        return this.http.delete(this.globals.config.apiUrl + "/vendors/" + id);
    };
    VendorsService.prototype.deleteDraft = function (id) {
        return this.http.delete(this.globals.config.apiV2Url + "/admin/draft-vendors/" + id);
    };
    VendorsService.prototype.deleteImage = function (id, key, serviceType) {
        return this.http.delete(this.globals.config.apiUrl + "/vendors/" + id + "/images/" + serviceType + "/" + encodeURIComponent(key));
    };
    VendorsService.prototype.deleteLogoFromDraft = function (id) {
        return this.http.delete(this.globals.config.apiV2Url + "/admin/draft-vendors/" + id + "/logo");
    };
    VendorsService.prototype.deleteImageFromDraft = function (id, key, serviceType) {
        return this.http.delete(this.globals.config.apiV2Url + "/admin/draft-vendors/" + id + "/images/" + serviceType + "/" + encodeURIComponent(key));
    };
    VendorsService.prototype.deleteOffering = function (id) {
        return this.http.delete(this.globals.config.apiUrl + "/offerings/" + id);
    };
    VendorsService.prototype.getOffering = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/offerings/" + id);
    };
    VendorsService.prototype.getNumberOfOfferingsWaitingForApproval = function () {
        var _this = this;
        return this.http.get(this.globals.config.apiV2Url + "/admin/offerings/on-approval/count").pipe(tap(function (res) { return _this.newOfferingsCount$.next(res.result); }));
    };
    VendorsService.prototype.getRegistrations = function (params) {
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/admin/vendors/registrations?" + query);
    };
    VendorsService.prototype.markRegistrationsAsRead = function (registrations) {
        var _this = this;
        return this.http.post(this.globals.config.apiV2Url + "/admin/vendors/registrations/unread", { registrationIds: registrations }).pipe(switchMap(function () { return _this.newRegistrationsCount$.pipe(take(1)); }), tap(function (oldRegistrationsCount) {
            var newRegistrationsCount = oldRegistrationsCount - registrations.length;
            _this.newRegistrationsCount$.next(newRegistrationsCount);
        }));
    };
    VendorsService.prototype.updateOffering = function (id, update) {
        var _this = this;
        return this.http.put(this.globals.config.apiUrl + "/offerings/" + id, update).pipe(tap(function () { return _this.reduceOfferingsWaitingForApproval(); }));
    };
    VendorsService.prototype.updateVendorOrdering = function (updateDetails) {
        //uvo: update vendor ordering
        return this.http.post(this.globals.config.apiUrl + "/vendors/uvo", updateDetails);
    };
    VendorsService.prototype.updateOfferingOrderForVendor = function (vendorId, updateDetails) {
        //uvo: update vendor ordering
        return this.http.post(this.globals.config.apiUrl + "/vendors/" + vendorId + "/reorderofferings", updateDetails);
    };
    VendorsService.prototype.getOffersWaitingForApproval = function (requestParams) {
        var query = requestQuery(requestParams);
        return this.http.get(this.globals.config.apiV2Url + "/admin/offerings?" + query);
    };
    VendorsService.prototype.reduceOfferingsWaitingForApproval = function () {
        var oldOfferingsCount = this.newOfferingsCount$.getValue();
        if (oldOfferingsCount) {
            this.newOfferingsCount$.next(oldOfferingsCount - 1);
        }
    };
    VendorsService.prototype.approveOffering = function (id) {
        var _this = this;
        return this.http.put(this.globals.config.apiUrl + "/offerings/approve/" + id, {}).pipe(tap(function () { return _this.reduceOfferingsWaitingForApproval(); }));
    };
    VendorsService.prototype.rejectOffering = function (id) {
        var _this = this;
        return this.http.put(this.globals.config.apiUrl + "/offerings/reject/" + id, {}).pipe(tap(function () { return _this.reduceOfferingsWaitingForApproval(); }));
    };
    VendorsService.prototype.getVendorReviews = function (id) {
        return this.http.get(this.globals.config.apiUrl + "/reviews/vendor/" + id);
    };
    VendorsService.prototype.getNewRegistrationsCount = function () {
        var _this = this;
        return this.http.get(this.globals.config.apiV2Url + "/admin/vendors/registrations/unread").pipe(tap(function (res) { return _this.newRegistrationsCount$.next(res.result); }));
    };
    VendorsService.prototype.approveDeleteOffering = function (id) {
        var _this = this;
        return this.http.put(this.globals.config.apiUrl + "/offerings/approve-delete/" + id, {}).pipe(tap(function () { return _this.reduceOfferingsWaitingForApproval(); }));
    };
    VendorsService.prototype.rejectDeleteOffering = function (id) {
        var _this = this;
        return this.http.put(this.globals.config.apiUrl + "/offerings/reject-delete/" + id, {}).pipe(tap(function () { return _this.reduceOfferingsWaitingForApproval(); }));
    };
    VendorsService.prototype.getVendors = function (params) {
        this.vendorsRequestParams$.next(params);
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/admin/vendors?" + query);
    };
    VendorsService.prototype.getPolicy = function () {
        return this.http
            .get(this.globals.config.apiV2Url + "/policy")
            .pipe(map(function (res) { return res.result.policy; }));
    };
    VendorsService.prototype.updatePolicy = function (policy) {
        return this.http
            .patch(this.globals.config.apiV2Url + "/policy", policy)
            .pipe(map(function (res) { return res.result.policy; }));
    };
    VendorsService.prototype.getDraftVendors = function (params) {
        this.vendorsRequestParams$.next(params);
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/admin/draft-vendors?" + query);
    };
    VendorsService.ngInjectableDef = i0.defineInjectable({ factory: function VendorsService_Factory() { return new VendorsService(i0.inject(i1.HttpClient), i0.inject(i2.Globals)); }, token: VendorsService, providedIn: "root" });
    return VendorsService;
}());
export { VendorsService };
