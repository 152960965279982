import { ChangeDetectorRef, OnDestroy, OnInit, } from "@angular/core";
import { ROUTES, ROUTES2 } from "./sidebar-routes.config";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "../../services/userService/user.service";
import { SseService } from "../../services/sse.service";
import { OrdersService } from "app/services/orders/orders.service";
import { VendorsService } from "../../services/vendors/vendors.service";
import { SseEventTypes } from "../../@models/sse-events.model";
import { SseEndpointsSuffixes } from "../../@constants/sse-endpoints-suffixes.constant";
import { switchMap, take } from "rxjs/operators";
import { IOffering, } from "../../@models/offerings.model";
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(orderService, userService, router, route, translate, vendorsService, cdr, sseService) {
        this.orderService = orderService;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.translate = translate;
        this.vendorsService = vendorsService;
        this.cdr = cdr;
        this.sseService = sseService;
        this.user = {};
    }
    SidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        $.getScript("./assets/js/app-sidebar.js");
        this.userService.getUser().subscribe(function (user) {
            _this.user = user;
            if (user.role == "accounting") {
                _this.menuItems = ROUTES2.filter(function (menuItem) { return menuItem; });
            }
            else {
                _this.menuItems = ROUTES.filter(function (menuItem) { return menuItem; });
            }
            _this.initSubscriptions();
            // this.orderService.getUndecidedCancelations().subscribe((orders) => {
            //   this.menuItems.find((item) => item.title == "Orders").badge =
            //     orders.length;
            //   this.cdr.detectChanges();
            // });
            _this.getNewVendorsRegistrationsCount();
            _this.getNumberOfOfferingsWaitingForApproval();
            // setInterval(() => {
            //     this.orderService.getUndecidedCancelations().subscribe((orders) => {
            //         this.menuItems.find(item => item.title == 'Orders').badge = orders.length;
            //         this.cdr.detectChanges();
            //     })
            // }, 10000)
        }, function (err) {
            console.log(err);
        });
    };
    SidebarComponent.prototype.getNewVendorsRegistrationsCount = function () {
        this.vendorsService.getNewRegistrationsCount().subscribe(function (res) { }, function (err) {
            console.log(err);
        });
    };
    SidebarComponent.prototype.initSubscriptions = function () {
        var _this = this;
        this.subscriptions = [
            this.vendorsService.newRegistrationsCount$.subscribe(function (registrations) {
                var vendorsMenuitem = _this.menuItems.find(function (item) { return item.title === "Vendors"; });
                if (vendorsMenuitem) {
                    vendorsMenuitem.submenu.find(function (item) { return item.title === "Registrations"; }).badge = registrations;
                    _this.cdr.detectChanges();
                }
            }),
            this.vendorsService.newOfferingsCount$.subscribe(function (offerings) {
                var vendorsMenuitem = _this.menuItems.find(function (item) { return item.title === "Vendors"; });
                if (vendorsMenuitem) {
                    vendorsMenuitem.submenu.find(function (item) { return item.title === "Offering Approval"; }).badge = offerings;
                    _this.cdr.detectChanges();
                }
            }),
            this.sseService
                .createEventSource(SseEndpointsSuffixes.vendorsRegistrations, [
                SseEventTypes.vendorRegistrationCreated,
            ])
                .pipe(switchMap(function () {
                return _this.vendorsService.newRegistrationsCount$.pipe(take(1));
            }))
                .subscribe(function (registrationsCount) {
                _this.vendorsService.newRegistrationsCount$.next(registrationsCount + 1);
            }),
            this.sseService
                .createEventSource(SseEndpointsSuffixes.offerings, [
                SseEventTypes.offeringCreated,
                SseEventTypes.offeringUpdated,
            ])
                .subscribe(function (event) {
                if (event.data.isRead === false) {
                    _this.getNumberOfOfferingsWaitingForApproval();
                    _this.vendorsService.offeringSseEvent$.next(event.data);
                }
            }),
        ];
    };
    SidebarComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    SidebarComponent.prototype.getNumberOfOfferingsWaitingForApproval = function () {
        this.vendorsService.getNumberOfOfferingsWaitingForApproval().subscribe(function (_) { }, function (err) {
            console.log(err);
        });
    };
    //NGX Wizard - skip url change
    SidebarComponent.prototype.ngxWizardFunction = function (path) {
        if (path.indexOf("forms/ngx") !== -1) {
            this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
        }
    };
    return SidebarComponent;
}());
export { SidebarComponent };
