import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals } from "../../globals";
import { requestQuery } from "../../@utils/request-query.util";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../globals";
var UserService = /** @class */ (function () {
    function UserService(http, globals) {
        this.http = http;
        this.globals = globals;
    }
    UserService.prototype.getAll = function () {
        return this.http.get(this.globals.config.apiUrl + "/users");
    };
    UserService.prototype.getPaginatedList = function (params) {
        var query = requestQuery(params);
        return this.http.get(this.globals.config.apiV2Url + "/admin/users?" + query);
    };
    UserService.prototype.getReport = function (params) {
        var query = requestQuery(params);
        var headers = new HttpHeaders({
            "content-type": "application/octet-stream",
        });
        return this.http.get(this.globals.config.apiV2Url + "/admin/users/report/file?" + query, { headers: headers, responseType: "blob" });
    };
    UserService.prototype.getUser = function () {
        return this.http.get(this.globals.config.apiUrl + "/users/me");
    };
    UserService.prototype.createVendorUser = function (user) {
        return this.http.post(this.globals.config.apiV2Url + "/vendors/" + user._vendor + "/users/create", user);
    };
    UserService.prototype.updateVendorUser = function (user) {
        return this.http.put(this.globals.config.apiV2Url + "/vendors/" + user._vendor + "/users/" + user._id + "/edit", user);
    };
    UserService.prototype.getVendorUsers = function (vendorId) {
        return this.http.get(this.globals.config.apiV2Url + "/vendors/" + vendorId + "/users");
    };
    UserService.prototype.blockUser = function (id) {
        return this.http.post(this.globals.config.apiUrl + "/users/" + id + "/block", {});
    };
    UserService.prototype.blockUserV2 = function (vendorId, userId, isBlocked) {
        return this.http.put(this.globals.config.apiV2Url + "/vendors/" + vendorId + "/users/" + userId + "/block", { isBlocked: isBlocked });
    };
    UserService.prototype.unblockUser = function (id) {
        return this.http.post(this.globals.config.apiUrl + "/users/" + id + "/unblock", {});
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.Globals)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
